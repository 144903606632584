// @flow

import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import LoadingIndicator from './LoadingIndicator';
import CatalogTile from './tiles/CatalogTile';
import Duration from './Duration';

import { type SpaCategoryStore } from '../stores/SpaStore';
import type { TrackingExcursion } from '../types/tracking';
import type SpaOfferListDetails from '../models/SpaOfferListDetails';
import { tealiumDateFromDateObject, tealiumValue } from '../utils/tracking';
import tracking from '../tracking';

type Props = {
  linkBase: string,
  store: SpaCategoryStore,
};

@inject('breadcrumbRouterStore')
@observer
export default class SpaCatalogTiles extends Component<Props> {
  triggered = false;

  //is needed for view item list tracking

  render() {
    const { store, linkBase } = this.props;
    if (!store || store.isLoading) return <LoadingIndicator />;
    if (!store.items || store.items.length === 0) {
      return <p>Es stehen keine Anwendungen zur gewünschten Auswahl zur Verfügung.</p>;
    }

    if (this.triggered === false) {
      this.triggered = true;
      const excursions: TrackingExcursion[] = store.items.map((item: SpaOfferListDetails) => ({
        code: item.spaId,
        name: item.title,
        discount: '0.00',
        startDate: tealiumDateFromDateObject(new Date()),
        value: tealiumValue(item.price),
        quantity: '1',
        operator: {
          code: 'intern',
          name: 'intern-meinschiff',
        },
      }));

      tracking.viewListItem(this.props.breadcrumbRouterStore, excursions);
    }

    return (
      <div className="l-tiles mx-0">
        {store.items.map((offer, index) => (
          <div
            key={offer.spaId}
            onClick={() => tracking.setCurrentListItem(index + 1, this.props.breadcrumbRouterStore, {})}
          >
            <CatalogTile
              description={offer.category}
              booking={offer.isBooked && offer}
              link={`${linkBase}/${offer.spaId}`}
              imageSrc={offer.image}
              title={offer.title}
              footer={<Duration duration={offer.duration} />}
              price={offer.price}
              isStartingPrice={offer.isStartingPrice}
            />
          </div>
        ))}
      </div>
    );
  }
}

// @flow
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { autobind } from 'core-decorators';
import { routerShape } from 'react-router/lib/PropTypes';

import preload from '../../../components/pages/decorators/preload';
import List from '../../../components/lists/List';
import ManifestTile from '../../../components/tiles/ManifestTile';
import pinStorage from '../../../utils/pinStorage';
import storage from '../../../utils/storage';
import LoadingIndicator from '../../LoadingIndicator';
import ContentBox from '../../../components/ContentBox';
import InfoIcon from '../../../../assets/svg/inline/ic-info.inline.svg';
import { PinDialog } from '../../pin/PinDialog';

import type MasterStore from '../../../stores/MasterStore';
import type PartyMember from '../../../models/PartyMember';
import type DigitalHealthStore from '../../../stores/DigitalHealthStore';
import type TilesStore from '../../../stores/TilesStore';
import tracking from '../../../tracking';
import { Button } from '../../redesign/Button';
import { Link } from 'react-router';
import { Text } from '../../redesign/Text';
import { Alert } from '../../redesign/Alert';
import { IconSecurityCheck } from '../../redesign/icons/IconSecurityCheck';

type Props = {
  masterStore: MasterStore,
  digitalHealthStore: DigitalHealthStore,
  tilesStore: TilesStore,
};

type State = {
  showPinModal: boolean,
  partyMember: ?PartyMember,
  url: ?string,
  error: ?Object,
};

@inject('digitalHealthStore', 'tilesStore')
@preload({ masterStore: 'MasterStore' })
@observer
export default class PageManifestOverview extends Component<Props, State> {
  static breadcrumb = 'Schiffsmanifest';

  static contextTypes = {
    router: routerShape,
  };

  state = {
    showPinModal: false,
    partyMember: null,
    url: null,
    error: null,
  };

  trackPageView() {
    tracking.pageView(window.location.pathname, ['schiffsmanifest', 'startseite'], 'content');
  }

  componentDidMount() {
    this.checkPinState();
    const { digitalHealthStore } = this.props;
    digitalHealthStore.info();
    storage.remove('language');
    this.props.tilesStore.fetchAll();
    setTimeout(() => {
      this.trackPageView();
    }, 0);
  }

  checkPinState() {
    // TUICUNIT-911
    let error = pinStorage.getApiPinError();
    if (error) {
      const { masterStore } = this.props;
      const partyMember = masterStore.getTravelPartyMemberByMpi(error.mpi);

      if (partyMember) {
        this.setState({
          partyMember,
          showPinModal: true,
          error,
          url: pinStorage.getUrl(partyMember.mpi),
        });
      }

      pinStorage.removeApiPinError();
    }
  }

  @autobind
  onTileClick(partyMember: PartyMember, url: string) {
    // TUICUNIT-911
    const pin = partyMember ? pinStorage.getPin(partyMember.mpi) : null;

    if (pin || !partyMember.pinAvailable) {
      this.context.router.replace(url);
      return;
    }

    this.setState({
      showPinModal: true,
      partyMember,
      url,
    });
  }

  renderTravelPartyList() {
    const masterData = this.props.masterStore.masterData;
    const { tiles } = this.props.tilesStore;
    if (masterData.paymentAvailable || masterData.digitalHealthAvailable) {
      return (
        <section className="mt-8 space-y-10">
          {masterData.travelParty.map((member) => (
            <div key={member.mpi}>
              <Text type="h2" className="mb-4">
                {member.displayName}
              </Text>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                {tiles.schiffsManifest.visible ? (
                  <ManifestTile
                    travelParty={masterData.travelParty}
                    partyMember={member}
                    configEnabled={tiles.schiffsManifest.enabled}
                    configNotification={tiles.schiffsManifest.notification}
                    state={member.manifestCompleted && !member.manifestPreliminary ? 'done' : 'open'}
                    onClick={this.onTileClick}
                  />
                ) : null}
              </div>
            </div>
          ))}
        </section>
      );
    }

    return (
      <div className="l-row">
        <div className="l-col double">
          <section>
            <h1>Teilnehmer</h1>
            {tiles.schiffsManifest.visible ? (
              <List className="l-mod-sub">
                {masterData.travelParty.map((member, index) => {
                  let disabled = false;
                  let badge = member.manifestPreliminary
                    ? 'In Bearbeitung'
                    : member.manifestCompleted
                    ? null
                    : 'Daten sind unvollständig';

                  // TUICUNIT-2856 if tilesconfig enable ignore other state and badge
                  if (tiles.schiffsManifest.enabled === false) {
                    disabled = true;
                    badge = tiles.schiffsManifest.notification;
                  }
                  return (
                    <List.Item
                      key={member.mpi}
                      link=""
                      disabled={disabled}
                      badge={badge}
                      onClick={(e) => {
                        e.preventDefault();
                        this.onTileClick(member, `/manifest/manifest/${index}`);
                        return false;
                      }}
                    >
                      <List.Item.Header>{member.displayName}</List.Item.Header>
                      <List.Item.Text>
                        {member.manifestCompleted
                          ? 'Die Daten sind vollständig'
                          : 'Bitte vervollständigen Sie Ihr Schiffsmanifest'}
                      </List.Item.Text>
                    </List.Item>
                  );
                })}
              </List>
            ) : null}
          </section>
        </div>
      </div>
    );
  }

  // TUICUNIT-2919
  // TUICUNIT-3474: deprecated
  renderHealthHint() {
    const masterData = this.props.masterStore.masterData;
    //(TUICMRL-783) PartialSave ist only allow by more then 7 days
    const under7Days = !masterData.manifestAllowsPartialSave;
    const journeyOngoing = masterData.journeyOngoing;

    if (masterData.digitalHealthStateNotAvailable && under7Days && !journeyOngoing) {
      return (
        <ContentBox title="Technische Störung" titleIcon={<InfoIcon />}>
          <p>
            Wir bitten um Verständnis, dass der Gesundheitsfragebogen derzeit infolge einer technischen Herausforderung
            zeitweise nicht dargestellt werden kann. Wir bitten Sie in diesem Fall, es zu einem späteren Zeitpunkt
            nochmals zu versuchen.
          </p>
        </ContentBox>
      );
    }
    return null;
  }

  render() {
    const masterData = this.props.masterStore.masterData;
    const { digitalHealthStore, tilesStore } = this.props;
    // TUICUNIT-4312 checkin info box should be removed
    // let someCanCheckin = masterData.travelParty.some((m) => m.checkinEnabled);
    const someCouldCheckin = masterData.travelParty.some((m) => m.checkinAvailable && !m.checkinCompleted);
    const allNoneSchengen = masterData.travelParty.every((m) => m.schengenResident === false);

    if (tilesStore.isLoading) {
      return <LoadingIndicator />;
    }
    const { tiles } = tilesStore;
    // see the rules for displaying the box schengenResident
    // https://confluence.cellular.de/display/tuicdocmrl/Schiffsmanifest+overview
    const showCheckInBox = masterData.checkinEnabled && someCouldCheckin && !allNoneSchengen;
    const { partyMember, url, showPinModal, error } = this.state;

    // console.log('SURVEY_NOT_AVAILABLE :', masterData.digitalHealthStateNotAvailable);
    // console.log(digitalHealthStore.getQuestions);

    // TUICUNIT-2856 if tilesconfig enable ignore other state
    // TUICUNIT-4312 checkin info box should be removed
    // if (tiles.onlineCheckIn.enabled === false) {
    //   someCanCheckin = false;
    // }

    return (
      <div className="page manifest">
        <div className="flex flex-col gap-4">
          {!!tiles.onlineCheckIn.enabled && !!someCouldCheckin && (
            <Alert
              mode="positive"
              headline="Online Check-in freigeschaltet"
              icon={<IconSecurityCheck />}
              className="my-2"
            >
              <Text>
                Bitte füllen Sie Ihr Schiffsmanifest vollständig aus, um online einzuchecken. Bis 24h vor der Abreise
                können Sie noch den Online Check-In nutzen und damit von einem schnelleren Bording vor Ort profitieren.
              </Text>
            </Alert>
          )}
          <Text type="md">
            Das Schiffsmanifest ist ein Dokument, das für Reisen auf dem Wasser für jeden Gast zwingend vorgeschrieben
            ist. Um eine reibungslose Einschiffung zu gewährleisten, benötigen wir vorab einige persönliche Daten von
            Ihnen. Bitte denken Sie daran, uns diese bis spätestens 6 Wochen vor Reiseantritt vollständig zu
            übermitteln.
          </Text>
          <div>
            {tiles.einreisebestimmungen.visible ? (
              <div className="space-y-6 mt-6">
                <Text type="h2">Ihre Einreisebestimmungen</Text>
                <Text type="md">
                  Bitte beachten Sie die Einreisebestimmungen für Ihre Reise. Wir haben diese für Sie persönlich
                  zusammengestellt.
                </Text>
                <div className="flex">
                  <Button
                    as={Link}
                    disabled={!tiles.einreisebestimmungen.enabled}
                    to="/manifest/einreisebestimmungen"
                    onClick={() => tracking.click('content.button.Zu den Einreisebestimmungen')}
                  >
                    Zu den Einreisebestimmungen
                  </Button>
                </div>
              </div>
            ) : null}
          </div>
          {/*TUICUNIT-3474: this.renderHealthHint() */}

          {this.renderTravelPartyList()}

          {showPinModal && (
            <PinDialog
              url={url}
              partyMember={partyMember}
              error={error}
              btnText="manifest"
              onClose={() => {
                this.setState({
                  showPinModal: false,
                  error: null,
                });
              }}
            />
          )}
        </div>
      </div>
    );
  }
}

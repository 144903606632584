// @flow

import React, { Component, type Node } from 'react';

import Headline from '../Headline';
import HeroImage from '../HeroImage';
import InfoBox from '../InfoBox';
import SpaCatalogTiles from '../SpaCatalogTiles';

import { type SpaCategoryStore } from '../../stores/SpaStore';

type Props = {
  headline: string,
  headlineIcon?: ?string,
  imageSrc: ?string,
  description?: ?string,
  defaultHint?: ?boolean,
  filter?: ?Node,
  store: SpaCategoryStore,
  linkBase: string,
};

export default class HealthAndBeautyLayout extends Component<Props> {
  render() {
    return (
      <div className="page">
        <HeroImage src={this.props.imageSrc} alt="" className="l-mod-sub" />
        <div className="l-row mt-4">
          <div className="l-col double">
            <p
              dangerouslySetInnerHTML={{
                __html: this.props.description,
              }}
            />
            {this.props.defaultHint ? (
              <InfoBox noIcon>
                <strong>Für Bordbegeisterte:</strong> Profitieren Sie während der Liegezeiten an Hafentagen von einem
                Preisvorteil auf ausgewählte Leistungen.
              </InfoBox>
            ) : null}
          </div>
        </div>

        <div className="mb-6">{this.props.filter || <h1>Übersicht aller Anwendungen</h1>}</div>
        <SpaCatalogTiles store={this.props.store} linkBase={this.props.linkBase} />
      </div>
    );
  }
}

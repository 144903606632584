var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
export var IconLogout = function (_a) {
    var props = __rest(_a, []);
    return (React.createElement("svg", __assign({ width: "22", height: "20", viewBox: "0 0 22 20", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("path", { d: "M11.0002 1.83852L1.33557 6.6708C0.96509 6.85604 0.514585 6.70588 0.329344 6.33539C0.144102 5.96491 0.294271 5.5144 0.664755 5.32916L10.3965 0.463322C10.7765 0.273317 11.2238 0.273271 11.6039 0.463321L21.3356 5.32916C21.7061 5.5144 21.8562 5.96491 21.671 6.33539C21.4857 6.70588 21.0352 6.85604 20.6648 6.6708L11.0002 1.83852Z", fill: "currentColor" }),
        React.createElement("path", { d: "M11 11.75C10.3096 11.75 9.75 12.3096 9.75 13V16C9.75 16.4142 9.41421 16.75 9 16.75C8.58579 16.75 8.25 16.4142 8.25 16V13C8.25 11.4812 9.48119 10.25 11 10.25C12.5188 10.25 13.75 11.4812 13.75 13V16C13.75 16.4142 13.4142 16.75 13 16.75C12.5858 16.75 12.25 16.4142 12.25 16V13C12.25 12.3096 11.6904 11.75 11 11.75Z", fill: "currentColor" }),
        React.createElement("path", { d: "M3.75 9C3.75 8.58579 3.41421 8.25 3 8.25C2.58579 8.25 2.25 8.58579 2.25 9V17C2.25 18.5188 3.48122 19.75 5 19.75H17C18.5188 19.75 19.75 18.5188 19.75 17V9C19.75 8.58579 19.4142 8.25 19 8.25C18.5858 8.25 18.25 8.58579 18.25 9V17C18.25 17.6904 17.6904 18.25 17 18.25H5C4.30964 18.25 3.75 17.6904 3.75 17V9Z", fill: "currentColor" })));
};

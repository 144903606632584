// @flow

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { autobind } from 'core-decorators';
import range from 'lodash/range';
import { twMerge } from 'tailwind-merge';


import FormSelect from './Select';
import ErrorMessage from './ErrorMessage';
import { adjustedDate } from '../../utils/date';

type Month = {
  value: number,
  label: string,
};

const MONTHS: Month[] = [
  { value: 0, label: 'Januar' },
  { value: 1, label: 'Februar' },
  { value: 2, label: 'März' },
  { value: 3, label: 'April' },
  { value: 4, label: 'Mai' },
  { value: 5, label: 'Juni' },
  { value: 6, label: 'Juli' },
  { value: 7, label: 'August' },
  { value: 8, label: 'September' },
  { value: 9, label: 'Oktober' },
  { value: 10, label: 'November' },
  { value: 11, label: 'Dezember' },
];

export type DateType = ?(false | Date);

type Props = {
  name: string,
  label?: string,
  value: string,
  // The lowest year that is selectable
  minYear: number,
  // The highest year that is selectable
  maxYear: number,
  onBlur?: (...args: any) => void,
  mandatory?: boolean,
  disabled?: boolean,
  error?: string,
  hasError?: boolean,
  onChange?: (value: DateType) => void,
};

type State = { localError?: ?string | ?boolean };

class FormDate extends React.Component<Props, State> {
  _year: ?FormSelect;
  _month: ?FormSelect;
  _day: ?FormSelect;

  static contextTypes = {
    registerFormElement: PropTypes.func,
    validateField: PropTypes.func,
  };

  static childContextTypes = {
    registerFormElement: PropTypes.func,
  };

  state = {};

  componentDidMount() {
    this.context.registerFormElement &&
      this.context.registerFormElement(this.props.name, this);
  }

  getChildContext() {
    return {
      // prohibt leakage of FormSelect values to parent Block
      registerFormElement: () => {},
    };
  }

  get value(): string {
    // Return a Date timestamp in UTC timezone
    if (!this._year || !this._month || !this._day) {
      return null;
    }
    if (!this._year.value && !this._month.value && !this._day.value) {
      return null;
    }
    if (!this._year.value || !this._month.value || !this._day.value) {
      return false;
    }

    const y = +this._year.value;
    const m = +this._month.value + 1;
    const d = +this._day.value;

    return `${y}-${m < 10 ? '0' + m : m}-${d < 10 ? '0' + d : d}T00:00:00Z`;
  }

  validate() {
    const { validateField } = this.context;
    if (validateField) {
      this.setState({
        localError:
          validateField(this.props.name, this.value) ||
          (this.value && this.inValidScratchMonth()
            ? 'Bitte geben Sie ein gültiges Datum an'
            : null),
      });
    }
  }

  // due to the possiblity to enter e.g 30th february
  inValidScratchMonth() {
    if (!this._year || !this._month || !this._day) {
      return false;
    }
    const year = this._year.value;
    const month = this._month.value;
    const day = this._day.value;

    const tempDate = adjustedDate(new Date(year, month, day));

    if (
      tempDate.getFullYear() === parseInt(year, 10) &&
      tempDate.getMonth() === parseInt(month, 10)
    ) {
      return false;
    }
    return true;
  }

  clearLocalError() {
    this.setState({
      localError: false,
    });
  }

  setDay(value: number) {
    if (this._day) this._day.value = value;
  }

  setMonth(value: number) {
    if (this._month) this._month.value = value;
  }

  setYear(value: number) {
    if (this._year) this._year.value = value;
  }

  reset() {
    this.setDay(null);
    this.setMonth(null);
    this.setYear(null);
  }

  @autobind
  onChange() {
    const { onChange, value } = this.props;
    if (value || this.value) this.validate();
    if (onChange) onChange(adjustedDate(new Date(this.value)));
  }

  @autobind
  onBlur(value: any, ref: any) {
    const { onBlur } = this.props;
    if (ref === this._year) {
      this.validate();
    }
    if (onBlur) onBlur(adjustedDate(new Date(this.value)), this);
  }

  get hasChanged(): boolean {
    const initialDate = adjustedDate(new Date(this.props.value));
    return !!this.value || +adjustedDate(new Date(this.value)) !== +initialDate;
  }

  render() {
    const {
      name,
      label,
      value,
      minYear,
      maxYear,
      mandatory,
      disabled,
      hasError,
    } = this.props;
    const errorMessage = this.state.localError || this.props.error;
    const error = !!(hasError || errorMessage);

    const dayOptions = range(1, 32).map(day => ({
      label: `${day}`,
      value: `${day}`,
    }));
    const monthOptions = MONTHS.map(opt => ({
      ...opt,
      value: `${opt.value}`,
    }));
    const yearOptions = range(maxYear, minYear - 1).map(year => ({
      label: `${year}`,
      value: `${year}`,
    }));

    // SUPTUICMRS-2621
    let year = null;
    let month = null;
    let day = null;

    if (value) {
      try {
        let tmp = value.substring(0, 10);
        tmp = tmp.split('-');
        year = +tmp[0];
        month = +tmp[1] - 1;
        day = +tmp[2];
      } catch (exc) {
        console.log(exc);
      }
    }

    return (
      <div
        className={classNames({
          'form-date': true,
          disabled,
          error,
        })}
      >
        {label ? (
          <label
            className={twMerge('block font-semibold text-sm', error ? 'text-red-500' : '')}
          >
            {label}
            {mandatory ? '*' : ''}
          </label>
        ) : null}
        <div className="l-spread">
          <div className="l-spread_left">
            <FormSelect
              noErrorMessage
              hasError={error}
              disabled={disabled}
              name={`${name}.day`}
              value={day !== null ? `${day}` : null}
              placeholder="Tag"
              options={dayOptions}
              onChange={this.onChange}
              onBlur={this.onBlur}
              ref={ref => {
                this._day = ref;
              }}
            />
          </div>
          <div className="l-spread_center">
            <FormSelect
              noErrorMessage
              hasError={error}
              disabled={disabled}
              name={`${name}.month`}
              value={month !== null ? `${month}` : null}
              placeholder="Monat"
              options={monthOptions}
              onChange={this.onChange}
              onBlur={this.onBlur}
              ref={ref => {
                this._month = ref;
              }}
            />
          </div>
          <div className="l-spread_right">
            <FormSelect
              noErrorMessage
              hasError={error}
              disabled={disabled}
              name={`${name}.year`}
              value={year !== null ? `${year}` : null}
              placeholder="Jahr"
              options={yearOptions}
              onChange={this.onChange}
              onBlur={this.onBlur}
              ref={ref => {
                this._year = ref;
              }}
            />
          </div>
        </div>
        <ErrorMessage error={errorMessage} />
      </div>
    );
  }
}

export default FormDate;

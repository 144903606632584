import React, { useState } from 'react';
import { Dialog } from '../redesign/Dialog';
import pinStorage from '../../utils/pinStorage';
import { PinInput } from './PinInput';
import { Button } from '../redesign/Button';
import { USER_PIN_LENGTH } from '../../config/constants';
import { browserHistory } from 'react-router';
import { Text } from '../redesign/Text';
var submitButtonText = {
    manifest: 'Weiter zum Schiffsmanifest',
    payment: 'Weiter zu den Zahlungsmitteln',
    health: 'Weiter zum Gesundheitsfragebogen',
    checkin: 'Weiter zum Check-In',
    fallback: 'Weiter',
};
var PIN_LENGTH = USER_PIN_LENGTH;
export var PinDialog = function (_a) {
    var url = _a.url, onClose = _a.onClose, onSubmit = _a.onSubmit, partyMember = _a.partyMember, error = _a.error, _b = _a.btnText, btnText = _b === void 0 ? 'fallback' : _b;
    var _c = useState(''), pin = _c[0], setPin = _c[1];
    var handleClose = function () {
        if (partyMember) {
            pinStorage.removeUrl(partyMember.mpi);
        }
        onClose === null || onClose === void 0 ? void 0 : onClose();
    };
    var handleSubmit = function () {
        if (onSubmit) {
            onSubmit(pin);
            return;
        }
        if (partyMember) {
            pinStorage.setPin(partyMember.mpi, pin);
            browserHistory.replace(url);
            return;
        }
        browserHistory.replace('/404');
    };
    return (React.createElement(Dialog, { title: "Information", onClose: handleClose, className: "flex flex-col", isOpen: true },
        React.createElement("div", { className: "flex flex-col gap-3" },
            React.createElement(Text, { type: "h2" },
                "Bitte geben Sie die Sicherheits-PIN f\u00FCr ", partyMember === null || partyMember === void 0 ? void 0 :
                partyMember.firstName,
                ' ', partyMember === null || partyMember === void 0 ? void 0 :
                partyMember.lastName,
                " ein"),
            React.createElement(Text, null, "Dieser Bereich ist aus datenschutzrechtlichen Gr\u00FCnden mit einer Sicherheits-PIN gesch\u00FCtzt."),
            React.createElement(Text, null, "Die Sicherheits-PIN ergibt sich aus den letzten 4 Zeichen Ihrer angegebenen Personalausweis- oder Reisepassnummer.")),
        React.createElement(PinInput, { className: "my-8", pin: pin, onChange: setPin, onEnter: handleSubmit, length: PIN_LENGTH }),
        error && (React.createElement(Text, { className: "text-red-500" }, "Leider stimmt Ihre Eingabe nicht mit Ihrer Sicherheits-PIN \u00FCberein. Bitte \u00FCberpr\u00FCfen Sie Ihre Eingabe. Sollten Sie Probleme mit der Eingabe der Sicherheits-PIN haben, wenden Sie sich bitte an unser Serviceteam.")),
        React.createElement("div", { className: "flex justify-center" },
            React.createElement(Button, { variant: "primary", onClick: handleSubmit, disabled: pin.length !== PIN_LENGTH }, submitButtonText[btnText]))));
};

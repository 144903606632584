// TODO: add flow types

import { observable, action, computed } from 'mobx';

// import ManifestInfoList from '../models/ManifestInfoList';
import ManifestData from '../models/ManifestData';
import Regulations from '../models/Regulations';

export default class ManifestStore {
  @observable list = null;
  @observable regulations = null;
  @observable users = {};

  masterStore = null;
  api = null;

  constructor(api, masterStore) {
    this.api = api;
    this.masterStore = masterStore;
  }

  @computed
  get loadingList() {
    return this.list === null;
  }

  fetchRegulations() {
    return this.api
      .getRegulations(this.masterStore.user, this.masterStore.masterData)
      .then(
        action((regulations) => {
          this.regulations = new Regulations(regulations);
          return this.regulations;
        })
      );
  }

  fetchUserData(mpi) {
    return this.api.getForMpi(this.masterStore.user, mpi).then((data) => {
      return this.receiveUserData(mpi, data);
    });
  }

  @action
  receiveUserData(mpi, data) {
    this.users[mpi] = new ManifestData(
      data,
      this.masterStore.masterData.getPartyMember(mpi)
    );
    return this.users[mpi];
  }

  saveUserData(manifestData, newData) {
    manifestData.markAsSending();
    return this.api
      .sendForMpi(this.masterStore.user, manifestData.user.mpi, newData)
      .then(
        (response) => {
          if (response.manifestData) {
            manifestData.updateData(response.manifestData);
            manifestData.markAsSend();
            return manifestData;
          } else {
            throw new Error('Could not save, wrong data');
          }
        },
        (error) => {
          manifestData.receiveError(error);
          throw new Error('Could not save');
        }
      );
  }
}

// @flow
import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { autobind } from 'core-decorators';
import subDays from 'date-fns/sub_days';
import addDays from 'date-fns/add_days';

import FormNegatedCheckbox from '../../../components/form/NegatedCheckbox';
import LoadingIndicator from '../../../components/LoadingIndicator';
import LinkAsButton from '../../../components/LinkAsButton';
import Form from '../../../components/form/Form';
import FormBlock from '../../../components/form/Block';
import FormSelect from '../../../components/form/Select';
import LabeledInputBlock from '../../../components/LabeledInputBlock';
import FormInput from '../../../components/form/Input';
import RestrictedFormInput from '../../../components/form/RestrictedInput';
import FormCheckbox from '../../../components/form/Checkbox';
import FormButton from '../../../components/form/Button';
import FormTextarea from '../../../components/form/Textarea';
import FormModalSelect from '../../../components/form/ModalSelect';
import FormDate, { type DateType } from '../../../components/form/Date';
import { isAlphanumeric } from '../../../utils/string';

import FormValidation, {
  RIGHT_BEFORE_CRUISE_NUM_OF_DAYS,
} from '../../../models/ManifestFormValidation';
import type PartyMember from '../../../models/PartyMember';
import type ManifestData from '../../../models/ManifestData';
import { PictogramHumanProfile } from '../../redesign/icons/PictogramHumanProfile';
import { Accordion } from '../../redesign/Accordion';
import { twMerge } from 'tailwind-merge';
import { PictogramPeople } from '../../redesign/icons/PictogramPeople';
import { PictogramBlog } from '../../redesign/icons/PictogramBlog';
import { PictogramInformation } from '../../redesign/icons/PictogramInformation';
import { Text } from '../../redesign/Text';
import { Button } from '../../redesign/Button';
import { Link } from 'react-router';

type Props = {
  manifestData: ManifestData,
  formValidation: FormValidation,
  partyMember: PartyMember,
  onSubmit: (values: Object) => void,
  onError: () => void,
  allowBlockwiseSubmit: boolean,
  disabled: boolean,
  isCheckinSubRoute: boolean,
  onlyPassportAvailable: boolean,
  isInAppView: boolean,
  onOpenNewTab?: () => boolean,
  regulationsContent: React.ReactNode,
};

type State = {
  hasHandicap: boolean,
  disableHasVisaCheckbox: boolean,
  disableVisaFields: boolean,
  passportNumberInitialError: boolean,
};

@observer
export default class ManifestForm extends Component<Props, State> {
  _zipCodeRef: window.HTMLDivElement;
  _passportType: window.HTMLDivElement;
  _validUntil: window.HTMLDivElement;
  _validUntilEntryVisa: window.HTMLDivElement;
  _dateOfIssueVisa: window.HTMLDivElement;
  _handicapRef: window.HTMLDivElement;
  _acceptDataProtectionTermsRef: window.HTMLDivElement;
  _allergyInfoRef: window.HTMLDivElement;
  _allergyRef: window.HTMLDivElement;
  _individualRequestRef: window.HTMLDivElement;
  _mealRequestRef: window.HTMLDivElement;
  _hasVisa: window.HTMLDivElement;
  _passportNumberVisa: window.HTMLDivElement;
  _placeOfIssueVisa: window.HTMLDivElement;
  _passportTypeVisa: window.HTMLDivElement;
  _entryVisaFormBlock: window.HTMLDivElement;
  _passportNumber: HTMLInputElement;

  state = {
    hasHandicap: this.hasHandicap(),
    disableHasVisaCheckbox: this.disableHasVisa(),
    disableVisaFields:
      this.props.manifestData.data.entryVisa &&
      this.props.manifestData.data.entryVisa.hasVisa === false,
    passportNumberInitialError: false,
    hasErrorPersonalInfo: false,
    hasErrorContact: false,
    hasErrorCredentials: false,
    hasErrorOtherInformation: false,
  };

  componentDidMount() {
    this.setState({
      passportNumberInitialError: !this._passportNumber?.validate() || false,
    });
  }

  @autobind
  copyContactDataFrom(contactOptionId: string) {
    const omitKeys = ['approveMobilePhoneDisclosure'];
    this.props.manifestData.copyContactDataFrom(contactOptionId, omitKeys);
  }

  @autobind
  revalidateZipCode() {
    this._zipCodeRef.validate();
  }

  @autobind
  autoFillValidUntil(date: DateType) {
    if (!date) return;
    const endDate = addDays(date, 180);
    this._validUntilEntryVisa.setDay(endDate.getUTCDate());
    this._validUntilEntryVisa.setMonth(String(endDate.getUTCMonth()));
    this._validUntilEntryVisa.setYear(String(endDate.getUTCFullYear()));
  }

  @autobind
  changeDateOfIssue(date: DateType) {
    if (!date) return;
    const yesterday = subDays(date, 1);
    this._validUntil.setDay(yesterday.getUTCDate());
    this._validUntil.setMonth(String(yesterday.getUTCMonth()));
    this._validUntil.setYear(null);
  }

  /**
   * a change of passport type demands re-validation of passport validity
   */
  @autobind
  changePassportType() {
    if (this._validUntil.value) this._validUntil.validate();
  }

  /**
   * passport validity requires a valid passport type to be validated
   */
  @autobind
  changeValidUntil() {
    if (this._passportType.value) this._passportType.validate();
  }

  @autobind
  hasHandicap() {
    const { manifestData } = this.props;
    const formData = manifestData.data;
    const { otherInformation } = formData;
    const { dietary } = otherInformation;

    const handicap = this._handicapRef
      ? !!this._handicapRef.value
      : !!otherInformation.handicap;
    const allergy = this._handicapRef
      ? !!this._allergyRef.value && this._allergyRef.value.slice().length >= 1
      : !!dietary && !!dietary.allergy && dietary.allergy.slice().length >= 1;
    const allergyInfo = this._allergyInfoRef
      ? !!this._allergyInfoRef.value
      : !!dietary && !!dietary.allergyInfo;
    const mealRequest = this._mealRequestRef
      ? !!this._mealRequestRef.value &&
        this._mealRequestRef.value.slice().length >= 1
      : !!dietary &&
        !!dietary.mealRequest &&
        !!dietary.mealRequest.slice().length >= 1;
    const individualRequest = this._individualRequestRef
      ? !!this._individualRequestRef.value
      : !!dietary && !!dietary.individualRequest;

    return (
      handicap || allergy || allergyInfo || mealRequest || individualRequest
    );
  }

  @autobind
  changeHandicap() {
    const hasHandicap = this.hasHandicap();
    this.setState({
      hasHandicap,
    });

    // HINT: this construct is necessary, otherwise the user will have to
    // uncheck the acceptDataProtectionTerms-Checkbox first, before
    // removing all handicap settings, as that makes the checkbox disappear
    if (!hasHandicap) {
      // however, the stored value will still be used if present
      const { manifestData } = this.props;
      const formData = manifestData.data;
      this._acceptDataProtectionTermsRef._ref.checked =
        formData.otherInformation.acceptDataProtectionTerms || null;
    }
  }

  @autobind
  handleChanges() {
    const { manifestData } = this.props;
    // TODO Micha this is not a good solution one should compare the original manifestData with the changed one
    // console.log(Object.keys(manifestData.data).some(key => manifestData.data[key].hasChanged));
    manifestData.makeReady();
  }

  resetHasVisa(elem: string | any, val?: string) {
    if (!val) {
      val = elem;
      elem = null;
    } else if (val && elem && elem.state && elem.state.localError) {
      // clear an error once the user starts typing
      elem.clearLocalError();
    }

    this.setState({
      disableHasVisaCheckbox: this.disableHasVisa(),
    });

    if (!this._hasVisa.state.localError) return;

    // clear the hasVisa checkbox error once they start typing in the visa fields
    if (val) this._hasVisa.clearLocalError();
  }

  @autobind
  resetVisaForm() {
    if (!this._entryVisaFormBlock) return;
    this._entryVisaFormBlock.reset(false, 'hasVisa'); // skip hasVisa field
    this._entryVisaFormBlock.validate();
  }

  @autobind
  disableHasVisa() {
    const { manifestData } = this.props;
    const formData = manifestData ? manifestData.data : {};
    const entryVisa = formData && formData.entryVisa ? formData.entryVisa : {};

    const visaFormData = {
      passportNumber: this._passportNumberVisa
        ? this._passportNumberVisa.value
        : entryVisa.passportNumber,
      placeOfIssue: this._placeOfIssueVisa
        ? this._placeOfIssueVisa.value
        : entryVisa.placeOfIssue,
      dateOfIssue: this._dateOfIssueVisa
        ? this._dateOfIssueVisa.value
        : entryVisa.dateOfIssue,
      validUntil: this._validUntilEntryVisa
        ? this._validUntilEntryVisa.value
        : entryVisa.validUntil,
      passportType: this._passportTypeVisa
        ? this._passportTypeVisa.value
        : entryVisa.passportType,
    };

    return (
      !!visaFormData.passportNumber &&
      !!visaFormData.placeOfIssue &&
      !!visaFormData.dateOfIssue &&
      !!visaFormData.validUntil &&
      !!visaFormData.passportType
    );
  }

  @autobind
  changeEntryVisa(enableEntryFields: boolean) {
    enableEntryFields
      ? this._entryVisaFormBlock.validate()
      : this.resetVisaForm();

    const disableVisaFields = !enableEntryFields;
    this.setState({ disableVisaFields });
  }

  render() {
    const {
      manifestData,
      onSubmit,
      onError,
      formValidation,
      partyMember,
      disabled,
      allowBlockwiseSubmit,
      isCheckinSubRoute,
      onlyPassportAvailable,
      isInAppView,
      onOpenNewTab,
      regulationsContent,
    } = this.props;
    if (!manifestData) return null;
    const { saveError } = manifestData;

    const formData = manifestData.data;
    const formOptions = manifestData.options;
    const { contact, credentials, entryVisa, personalInfo, otherInformation } =
      formData;

    const showVisaInformation =
      formOptions &&
      formOptions.entryVisa &&
      formOptions.entryVisa.passportTypeOptions &&
      formOptions.entryVisa.passportTypeOptions.length > 0;

    const showPregnant24Weeks = personalInfo.salutation === 'Frau';

    const showAcceptHealthTerms =
      typeof otherInformation.acceptHealthTerms !== 'undefined';

    const isRightBeforeCruise =
      subDays(formValidation.debarkDate, RIGHT_BEFORE_CRUISE_NUM_OF_DAYS) <
      new Date();

    const currentYear = new Date().getUTCFullYear();
    const years = {
      dateOfBirth: { min: currentYear - 100, max: currentYear },
      dateOfIssue: { min: currentYear - 16, max: currentYear },
      validUntil: { min: currentYear, max: currentYear + 15 },
      dateOfIssueVisa: { min: currentYear - 1, max: currentYear + 2 },
    };
    const getError = (cat, type) =>
      (type
        ? (saveError && saveError[cat] && saveError[cat][type]) || []
        : (saveError && saveError[cat]) || []
      ).join(', ');

    const disableDateOfBirth = !partyMember.isBirthdateUpdateAllowed;
    const disableTitle = !partyMember.isTitleUpdateAllowed;

    return (
      <Form
        onSubmit={onSubmit}
        onError={onError}
        onErrorExtended={({ errors }) => {
          const hasCategoryMessages = (value) =>
            typeof value === 'object' && null !== value
              ? !!Object.values(value).filter(hasCategoryMessages).length
              : !!value;
          const hasCategoryError = (category) =>
            errors &&
            errors[category] &&
            !!Object.values(errors[category]).filter(hasCategoryMessages)
              .length;

          console.log('onExtendedError', errors);

          this.setState({
            hasErrorPersonalInfo: hasCategoryError('personalInfo'),
            hasErrorContact: hasCategoryError('contact'),
            hasErrorCredentials: hasCategoryError('credentials'),
            hasErrorOtherInformation: hasCategoryError('otherInformation'),
          });
        }}
        onChange={this.handleChanges}
        hasErrors={!!saveError}
        validationRules={formValidation ? formValidation.validationRules : {}}
        allowBlockwiseSubmit={allowBlockwiseSubmit}
      >
        <Accordion
          initialOpen={[
            this.state.hasErrorPersonalInfo ? 'person' : null,
            this.state.hasErrorContact ? 'contact' : null,
            this.state.hasErrorCredentials ? 'credentials' : null,
            this.state.hasErrorOtherInformation ? 'otherInformation' : null,
          ].filter(Boolean)}
          items={{
            person: {
              opener: () => (
                <Text
                  as="div"
                  className={twMerge(
                    'flex gap-2 items-center',
                    this.state.hasErrorPersonalInfo && 'text-red-700'
                  )}
                >
                  <PictogramHumanProfile />
                  Zur Person
                </Text>
              ),
              content: (
                <FormBlock name="personalInfo" alwaysPretendToHaveChanged>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                    <div>
                      {personalInfo.salutation ? (
                        <FormInput
                          disabled
                          name="salutation"
                          label="Anrede"
                          value={personalInfo.salutation}
                        />
                      ) : (
                        <FormSelect
                          mandatory
                          disabled={disabled}
                          name="salutation"
                          label="Anrede"
                          placeholder="Bitte wählen"
                          options={formOptions.personalInfo.salutationOptions}
                          value={personalInfo.salutation}
                          error={getError('personalInfo', 'salutation')}
                        />
                      )}
                    </div>
                    <div>
                      <FormSelect
                        disabled={disabled || disableTitle}
                        name="title"
                        label="Titel"
                        placeholder="Bitte wählen"
                        options={formOptions.personalInfo.titleOptions}
                        value={personalInfo.title}
                        error={getError('personalInfo', 'title')}
                      />
                    </div>
                    <div>
                      <FormInput
                        disabled
                        name="firstName"
                        label="Vorname"
                        value={personalInfo.firstName}
                      />
                      <p className="text-xs">
                        Zur Änderung von Namen wenden Sie sich bitte an Ihr
                        Reisebüro oder an unsere Service-Hotline.
                      </p>
                    </div>
                    <div>
                      <FormInput
                        disabled
                        name="lastName"
                        label="Nachname"
                        value={personalInfo.lastName}
                      />
                      <p className="text-xs">
                        Zur Änderung von Namen wenden Sie sich bitte an Ihr
                        Reisebüro oder an unsere Service-Hotline.
                      </p>
                    </div>
                  </div>
                  <div className="my-4">* Pflichtfeld</div>
                </FormBlock>
              ),
            },
            contact: {
              opener: () => (
                <Text
                  as="div"
                  className={twMerge(
                    'flex gap-2 items-center',
                    this.state.hasErrorContact && 'text-red-700'
                  )}
                >
                  <PictogramPeople />
                  Kontaktdaten
                </Text>
              ),
              content: (
                <FormBlock name="contact" alwaysPretendToHaveChanged>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                    <div>
                      <FormSelect
                        hasFrame
                        disabled={disabled}
                        name="copyContacts"
                        label="Kontaktdaten von anderem Teilnehmer übernehmen:"
                        placeholder="Bitte wählen"
                        options={formOptions.contact.copyOptions}
                        onChange={this.copyContactDataFrom}
                      />
                    </div>
                    <div>
                      <FormInput
                        mandatory
                        disabled={disabled}
                        name="streetAddress"
                        label="Straße und Hausnummer"
                        key={`streetAddress-${contact.streetAddress}`}
                        value={contact.streetAddress}
                        maxLength={60}
                        error={getError('contact', 'streetAddress')}
                      />
                    </div>
                    <div>
                      <FormInput
                        mandatory
                        disabled={disabled}
                        name="zipCode"
                        label="PLZ"
                        key={`zipCode-${contact.zipCode}`}
                        value={contact.zipCode}
                        maxLength={30}
                        ref={(ref) => {
                          this._zipCodeRef = ref;
                        }}
                        error={getError('contact', 'zipCode')}
                      />
                    </div>
                    <div>
                      <FormInput
                        mandatory
                        disabled={disabled}
                        name="city"
                        label="Ort"
                        key={`city-${contact.city}`}
                        value={contact.city}
                        maxLength={30}
                        error={getError('contact', 'city')}
                      />
                    </div>
                    <div>
                      <FormSelect
                        mandatory
                        disabled={disabled}
                        name="country"
                        label="Land"
                        placeholder="Bitte wählen"
                        key={`country-${contact.country}`}
                        value={contact.country}
                        options={formOptions.contact.countryOptions}
                        onChange={this.revalidateZipCode}
                        error={getError('contact', 'country')}
                      />
                    </div>
                    <div>
                      <FormInput
                        mandatory
                        disabled={disabled}
                        name="email"
                        label="E-Mail"
                        key={`email-${contact.email}`}
                        value={contact.email}
                        maxLength={100}
                        error={getError('contact', 'email')}
                      />
                      <p className="text-xs !m-0">
                        Diese E-Mail-Adresse gilt auch für E-Mail Nachrichten
                        während der Reise (z.B. Bestätigungen für weitere
                        Reservierungen).
                      </p>
                    </div>
                    <div>
                      <FormInput
                        mandatory
                        disabled={disabled}
                        name="phone"
                        label="Telefonnummer"
                        key={`phone-${contact.phone}`}
                        value={contact.phone}
                        maxLength={100}
                        error={getError('contact', 'phone')}
                      />
                      <p className="text-xs">
                        Bitte geben Sie Ihre Telefonnummer ohne Leer- und
                        Sonderzeichen an.
                      </p>
                    </div>
                    <div>
                      <FormInput
                        mandatory
                        disabled={disabled}
                        name="mobile"
                        placeholder="0049"
                        label="Mobilnummer"
                        key={`mobile-${contact.mobile}`}
                        value={contact.mobile}
                        maxLength={100}
                        error={getError('contact', 'mobile')}
                      />
                      <p className="text-xs">
                        Bitte geben Sie Ihre Mobilnummer im Format 0049170123456
                        ohne Leer- und Sonderzeichen an. Sollten Sie keine
                        Mobilnummer besitzen, möchten wir Sie bitten die
                        Mobilnummer von Mitreisenden oder Ihrem Notfallkontakt
                        anzugeben.
                      </p>
                    </div>
                    <div className="px-1 md:col-span-2">
                      <FormCheckbox
                        mandatory
                        disabled={disabled}
                        name="approveMobilePhoneDisclosure"
                        label="Damit ich bei möglichen kurzfristigen Unregelmäßigkeiten vor und während der Reise ggf. informiert werden kann, erlaube ich TUI Cruises GmbH die Nutzung meiner Mobilnummer sowie die Weitergabe an den gebuchten Transportdienstleister, sofern ich eine An/Abreise über TUI Cruises GmbH gebucht habe."
                        checked={contact.approveMobilePhoneDisclosure}
                        error={getError(
                          'contact',
                          'approveMobilePhoneDisclosure'
                        )}
                      />
                    </div>
                  </div>
                  <div className="my-4">* Pflichtfeld</div>
                </FormBlock>
              ),
            },
            credentials: {
              opener: () => (
                <Text
                  as="div"
                  className={twMerge(
                    'flex gap-2 items-center',
                    this.state.hasErrorCredentials && 'text-red-700'
                  )}
                >
                  <PictogramBlog />
                  Ausweis
                </Text>
              ),
              content: (
                <>
                  <FormBlock name="credentials" alwaysPretendToHaveChanged>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                      <Text className="md:col-span-2">
                        {FormValidation.getCredentialRequirements(
                          onlyPassportAvailable
                        )}
                      </Text>
                      <div>
                        <FormSelect
                          mandatory
                          disabled={disabled}
                          value={credentials.passportType}
                          name="passportType"
                          label="Ausweistyp"
                          placeholder="Bitte wählen"
                          ref={(ref) => {
                            this._passportType = ref;
                          }}
                          options={formOptions.credentials.passportTypeOptions}
                          onChange={this.changePassportType}
                          error={getError('credentials', 'passportType')}
                        />
                      </div>
                      <div>
                        <FormSelect
                          mandatory
                          disabled={disabled}
                          value={credentials.nationality}
                          name="nationality"
                          label="Nationalität"
                          placeholder="Bitte wählen"
                          options={formOptions.credentials.nationalityOptions}
                          error={getError('credentials', 'nationality')}
                        />
                      </div>
                      <div>
                        <FormInput
                          mandatory
                          disabled={disabled}
                          name="passportNumber"
                          label="Ausweis- / Passnummer"
                          value={credentials.passportNumber}
                          maxLength={32}
                          error={getError('credentials', 'passportNumber')}
                          ref={(ref) => {
                            this._passportNumber = ref;
                          }}
                          validate={(value, newValue) => {
                            return !newValue || isAlphanumeric(newValue)
                              ? newValue.toUpperCase()
                              : value;
                          }}
                          onFocus={() => {
                            if (this.state.passportNumberInitialError) {
                              this._passportNumber.value = '';
                              this.setState({
                                passportNumberInitialError: false,
                              });
                              this.handleChanges();
                            }
                          }}
                        />
                      </div>
                      <div>
                        <FormInput
                          mandatory
                          disabled={disabled}
                          name="placeOfBirth"
                          label="Geburtsort"
                          value={credentials.placeOfBirth}
                          maxLength={32}
                          error={getError('credentials', 'placeOfBirth')}
                        />
                      </div>
                      <div>
                        <FormInput
                          mandatory
                          disabled={disabled}
                          name="placeOfIssue"
                          label="Ausstellungsort"
                          value={credentials.placeOfIssue}
                          maxLength={32}
                          error={getError('credentials', 'placeOfIssue')}
                        />
                      </div>
                      <div>
                        <FormDate
                          mandatory
                          disabled={disabled || disableDateOfBirth}
                          name="dateOfBirth"
                          label="Geburtsdatum"
                          value={credentials.dateOfBirth}
                          minYear={years.dateOfBirth.min}
                          maxYear={years.dateOfBirth.max}
                          error={getError('credentials', 'dateOfBirth')}
                        />
                        {disableDateOfBirth && (
                          <p className="text-xs">
                            Zur Änderung des Geburtsdatums wenden Sie sich bitte
                            an Ihr Reisebüro oder an unsere Service-Hotline.
                          </p>
                        )}
                      </div>
                      <div>
                        <FormDate
                          mandatory
                          disabled={disabled}
                          name="dateOfIssue"
                          label="Ausstellungsdatum"
                          value={credentials.dateOfIssue}
                          minYear={years.dateOfIssue.min}
                          maxYear={years.dateOfIssue.max}
                          error={getError('credentials', 'dateOfIssue')}
                          onChange={this.changeDateOfIssue}
                        />
                      </div>
                      <div>
                        <FormInput
                          disabled={disabled}
                          name="birthName"
                          label="Geburtsname"
                          value={credentials.birthName}
                          maxLength={32}
                          error={getError('credentials', 'birthName')}
                        />
                      </div>
                      <div>
                        <FormDate
                          mandatory
                          disabled={disabled}
                          name="validUntil"
                          label="Gültig bis"
                          ref={(ref) => {
                            this._validUntil = ref;
                          }}
                          value={credentials.validUntil}
                          minYear={years.validUntil.min}
                          maxYear={years.validUntil.max}
                          onChange={this.changeValidUntil}
                          error={getError('credentials', 'validUntil')}
                        />
                      </div>
                      <div>
                        <FormSelect
                          mandatory
                          disabled={disabled}
                          value={credentials.profession}
                          name="profession"
                          label="Beruf"
                          placeholder="Bitte wählen"
                          options={formOptions.credentials.professionOptions}
                          error={getError('credentials', 'profession')}
                        />
                        <p className="text-xs">
                          Die Angabe wird zur Einreise in manchen Ländern
                          benötigt.
                        </p>
                      </div>
                      <div className="px-1 md:col-span-2 flex flex-col gap-2">
                        <h2>Einreisebestimmungen*</h2>
                        <FormCheckbox
                          className="big"
                          disabled={disabled}
                          name="entryRequirementsAccepted"
                          label="Hiermit bestätige ich, die Einreisebestimmungen für meine Reise gelesen zu haben und akzeptiere diese."
                          checked={credentials.entryRequirementsAccepted}
                        />
                        {credentials.hasVisa !== null &&
                        !showVisaInformation ? (
                          <div className="my-5">
                            <FormCheckbox
                              className="big"
                              disabled={disabled}
                              name="hasVisa"
                              label="Ich bestätige ebenfalls, dass ich über ein gültiges Visum für die USA bzw. eine gültige ESTA Anmeldung verfüge und diese ausgedruckt zum Check-in mitführe."
                              checked={credentials.hasVisa}
                            />
                          </div>
                        ) : null}
                        <div className="flex justify-end mb-4">
                          {regulationsContent}
                        </div>
                      </div>
                    </div>
                  </FormBlock>
                  {showVisaInformation ? (
                    <div>
                      <FormBlock
                        name="entryVisa"
                        alwaysPretendToHaveChanged={isRightBeforeCruise}
                        ref={(ref) => {
                          this._entryVisaFormBlock = ref;
                        }}
                      >
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                          <div>
                            <FormInput
                              disabled={
                                disabled || this.state.disableVisaFields
                              }
                              name="visaPassportNumber"
                              label="Visum Nr.*"
                              ref={(ref) => {
                                this._passportNumberVisa = ref;
                              }}
                              onChange={this.resetHasVisa.bind(
                                this,
                                this._passportNumberVisa
                              )}
                              value={entryVisa && entryVisa.passportNumber}
                              showRemaining
                              maxLength={32}
                              error={getError('entryVisa', 'passportNumber')}
                            />
                          </div>
                          <div>
                            <FormSelect
                              disabled={
                                disabled || this.state.disableVisaFields
                              }
                              value={entryVisa && entryVisa.passportType}
                              ref={(ref) => {
                                this._passportTypeVisa = ref;
                              }}
                              onChange={this.resetHasVisa.bind(this)}
                              name="visaPassportType"
                              label="Visumkategorie*"
                              placeholder="Bitte wählen"
                              options={
                                formOptions.entryVisa.passportTypeOptions
                              }
                              error={getError('entryVisa', 'passportType')}
                            />
                          </div>
                          <div>
                            <FormDate
                              disabled={
                                disabled || this.state.disableVisaFields
                              }
                              name="visaDateOfIssue"
                              label="Ausstellungsdatum*"
                              ref={(ref) => {
                                this._dateOfIssueVisa = ref;
                              }}
                              onChange={() => {
                                this.autoFillValidUntil();
                                this.resetHasVisa.bind(this);
                              }}
                              value={entryVisa && entryVisa.dateOfIssue}
                              minYear={years.dateOfIssueVisa.min}
                              maxYear={years.dateOfIssueVisa.max}
                              error={getError('entryVisa', 'validUntil')}
                            />
                          </div>
                          <div>
                            <FormDate
                              disabled={
                                disabled || this.state.disableVisaFields
                              }
                              name="visaValidUntil"
                              label="Gültig bis*"
                              ref={(ref) => {
                                this._validUntilEntryVisa = ref;
                              }}
                              onChange={this.resetHasVisa.bind(this)}
                              value={entryVisa && entryVisa.validUntil}
                              minYear={years.dateOfIssueVisa.min}
                              maxYear={years.dateOfIssueVisa.max}
                              error={getError('entryVisa', 'validUntil')}
                            />
                          </div>
                          <div>
                            <FormInput
                              disabled={
                                disabled || this.state.disableVisaFields
                              }
                              name="visaPlaceOfIssue"
                              label="Ausstellungsort*"
                              value={entryVisa && entryVisa.placeOfIssue}
                              ref={(ref) => {
                                this._placeOfIssueVisa = ref;
                              }}
                              onChange={this.resetHasVisa.bind(
                                this,
                                this._placeOfIssueVisa
                              )}
                              showRemaining
                              maxLength={32}
                              error={getError('entryVisa', 'placeOfIssue')}
                            />
                          </div>
                        </div>
                        {formOptions.entryVisa.indiaCruise && (
                          <div className="flex flex-col gap-4">
                            <Text type="h3">Bei Reiseantritt ohne Visum*</Text>
                            <div>
                              <FormNegatedCheckbox
                                ref={(ref) => {
                                  this._hasVisa = ref;
                                }}
                                disabled={
                                  disabled || this.state.disableHasVisaCheckbox
                                }
                                name="hasVisa"
                                label="Ich habe wissentlich kein Visum für Indien vorliegen und mir ist bewusst, dass ich in jeglichen indischen Häfen das Schiff nicht verlassen darf."
                                checked={
                                  entryVisa &&
                                  entryVisa.hasVisa === false &&
                                  !this.state.disableHasVisaCheckbox
                                }
                                onChange={this.changeEntryVisa}
                              />
                            </div>
                          </div>
                        )}
                      </FormBlock>
                    </div>
                  ) : null}
                  <div className="my-4">* Pflichtfeld</div>
                </>
              ),
            },
            otherInformation: {
              opener: () => (
                <Text
                  as="div"
                  className={twMerge(
                    'flex gap-2 items-center',
                    this.state.hasErrorOtherInformation && 'text-red-700'
                  )}
                >
                  <PictogramInformation />
                  Sonstige Informationen
                </Text>
              ),
              content: (
                <FormBlock
                  name="otherInformation"
                  alwaysPretendToHaveChanged={isRightBeforeCruise}
                >
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                    <Text className="md:col-span-2">
                      Wir bitten Sie, besondere Diätwünsche,
                      Lebensmittelunverträglichkeiten und Allergien schon vor
                      der Reise anzugeben, damit wir uns optimal auf Ihre
                      Wünsche einstellen können.
                    </Text>
                    <div className="md:col-span-2 px-1">
                      <Text type="h3" className="mb-4">
                        Ernährung
                      </Text>
                      <FormBlock name="dietary">
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                          <div>
                            <FormSelect
                              disabled={disabled}
                              name="mealRequest"
                              label="Bitte geben Sie die Art Ihrer Diät an:"
                              placeholder="Bitte wählen"
                              options={
                                formOptions.dietary.mealRequestOptions || []
                              }
                              value={
                                otherInformation &&
                                otherInformation.dietary &&
                                otherInformation.dietary.mealRequest
                              }
                              onChange={this.changeHandicap}
                              ref={(ref) => {
                                this._mealRequestRef = ref;
                              }}
                              error={getError('dietary', 'mealRequestOptions')}
                            />
                          </div>
                          <div>
                            <FormModalSelect
                              disabled={disabled}
                              name="allergy"
                              label="Bitte geben Sie die Art Ihrer Allergie an:"
                              triggerLabel="Bitte wählen"
                              options={formOptions.dietary.allergyOptions || []}
                              value={
                                otherInformation &&
                                otherInformation.dietary &&
                                otherInformation.dietary.allergy &&
                                otherInformation.dietary.allergy.slice()
                              }
                              onChange={this.changeHandicap}
                              ref={(ref) => {
                                this._allergyRef = ref;
                              }}
                            />
                          </div>
                          <div>
                            <FormTextarea
                              disabled={disabled}
                              showRemaining
                              maxLength={100}
                              name="individualRequest"
                              label="Weitere Kommentare zu Ihrer Diät:"
                              value={
                                otherInformation &&
                                otherInformation.dietary &&
                                otherInformation.dietary.individualRequest
                              }
                              error={getError('dietary', 'individualRequest')}
                              onChange={this.changeHandicap}
                              ref={(ref) => {
                                this._individualRequestRef = ref;
                              }}
                            />
                            <p>
                              Sollten Sie aus gesundheitlichen Gründen auf
                              spezielle Lebensmittel angewiesen sein,
                              kontaktieren Sie bitte mindestens acht Wochen vor
                              Ihrer Reise ihr zuständiges Reisebüro oder unseren
                              Kundenservice.
                            </p>
                          </div>
                          <div>
                            <FormTextarea
                              disabled={disabled}
                              showRemaining
                              maxLength={50}
                              name="allergyInfo"
                              label="Weitere Kommentare zu Ihrer Allergie:"
                              value={
                                otherInformation &&
                                otherInformation.dietary &&
                                otherInformation.dietary.allergyInfo
                              }
                              onChange={this.changeHandicap}
                              ref={(ref) => {
                                this._allergyInfoRef = ref;
                              }}
                              error={getError('dietary', 'allergyInfo')}
                            />
                            <p>
                              Bitte beachten Sie, dass in den an Bord
                              angebotenen Speisen Spuren von Allergenen
                              enthalten sein können und trotz aller Sorgfalt
                              Kreuzkontaminationen nicht auszuschließen sind.
                            </p>
                          </div>
                        </div>
                      </FormBlock>
                    </div>
                    <div>
                      <Text type="h3" className="mb-4">
                        Sicherheit
                      </Text>
                      <Text>
                        Bitte haben Sie Verständnis dafür, dass wir gemäß der
                        International Convention for the Safety of Life at Sea
                        (SOLAS) - einer UN-Konvention zur Schiffssicherheit -
                        verpflichtet sind folgende Daten zu erfragen.
                      </Text>
                    </div>
                    <div>
                      <Text type="h3" className="mb-4">
                        Kontakt für Notfälle
                      </Text>
                      <Text>
                        Bitte geben Sie Name und Telefonnummer einer Ihnen
                        nahestehenden Person an, die im Notfall kontaktiert
                        werden kann.
                      </Text>
                    </div>
                    <div>
                      <FormSelect
                        label="Behinderung"
                        disabled={disabled}
                        name="handicap"
                        placeholder="Es liegt keine Behinderung vor."
                        options={formOptions.otherInformation.handicapOptions}
                        value={otherInformation.handicap}
                        onChange={this.changeHandicap}
                        ref={(ref) => {
                          this._handicapRef = ref;
                        }}
                        error={getError('otherInformation', 'handicap')}
                      />
                    </div>
                    <div>
                      <FormBlock name="emergencyContact">
                        <div>
                          <FormInput
                            mandatory
                            disabled={disabled}
                            name="name"
                            label="Name"
                            value={otherInformation.emergencyContact.name}
                            maxLength={50}
                            error={getError(
                              'otherInformation',
                              'emergencyContact'
                            )}
                          />
                          <FormInput
                            mandatory
                            disabled={disabled}
                            name="phone"
                            label="Telefonnummer"
                            value={otherInformation.emergencyContact.phone}
                            maxLength={26}
                          />
                          <p className="text-xs">
                            Bitte geben Sie Ihre Telefonnummer ohne Leer- und
                            Sonderzeichen an.
                          </p>
                        </div>
                      </FormBlock>
                    </div>
                    <div
                      className={twMerge(
                        'md:col-span-2 px-1',
                        (!this.state.hasHandicap ||
                          otherInformation.acceptDataProtectionTerms) &&
                          'hidden'
                      )}
                    >
                      <Text
                        as="h3"
                        type="h3"
                        className="mb-4 font-semibold text-high-emphasis"
                      >
                        Gesundheitsdaten*
                      </Text>
                      <Text>
                        Zum Zwecke der Vorbereitung und Durchführung der Reise
                        verarbeiten wir die von Ihnen in diesem Reisemanifest
                        angegebenen Gesundheitsdaten (z.B.
                        Lebensmittelunverträglichkeiten, besondere Diäten und
                        Angaben zu körperlichen Einschränkungen), die Sie uns
                        noch nicht mit der Reiseanmeldung übermittelt haben.
                        Zweck dieser Verarbeitung ist es, Ihnen den Aufenthalt
                        an Bord so angenehm wie möglich zu gestalten. Weiterhin
                        übermitteln wir relevante Gesundheitsdaten (z.B.
                        besondere Bedürfnisse) an externe Bus- und
                        Flugunternehmen, damit diese Ihre reibungslose An- und
                        Abreise gewährleisten können. Ihre Angabe dieser ist
                        selbstverständlich freiwillig. Wenn Sie uns diese
                        Angaben nicht mitteilen, können wir unter Umständen
                        nicht so auf Ihre besonderen Bedürfnisse eingehen, wie
                        Sie es sich bei uns an Bord wünschen.
                        <br />
                        <br />
                        Wir bitten Sie deshalb um die Einwilligung, Ihre
                        Gesundheitsdaten speichern und ausschließlich zu dem
                        Zweck einer optimalen Betreuung an Bord, einer
                        reibungslosen An- und Abreise sowie zur Verhinderung von
                        gesundheitlichen Beeinträchtigungen verwenden zu dürfen.
                        Eine weitere Auswertung dieser Daten findet nicht statt.
                        <br />
                        <br />
                        Ihre Einwilligung können Sie jederzeit mit Wirkung für
                        die Zukunft durch eine kurze Nachricht per E&#8209;Mail
                        an &#8203;
                        <a
                          className="underline"
                          href="mailto:datenschutz@tuicruises.com"
                        >
                          datenschutz@tuicruises.com
                        </a>
                        &thinsp; oder per Post an TUI Cruises GmbH,
                        Datenschutzbeauftragter, Heidenkampsweg 58, 20097
                        Hamburg widerrufen.
                      </Text>
                      <FormCheckbox
                        disabled={disabled}
                        className="big"
                        name="acceptDataProtectionTerms"
                        label="Hiermit willige ich ein, dass meine Gesundheitsdaten zu dem Zweck einer optimalen Betreuung an Bord und zur Verhinderung von gesundheitlichen Beeinträchtigungen verwendet werden dürfen."
                        checked={otherInformation.acceptDataProtectionTerms}
                        ref={(ref) => {
                          this._acceptDataProtectionTermsRef = ref;
                        }}
                        error={getError(
                          'otherInformation',
                          'acceptDataProtectionTerms'
                        )}
                      />
                    </div>
                    {!!showAcceptHealthTerms && (
                      <div className="md:col-span-2 px-1">
                        <div className="form-covid">
                          <Text type="h3" className="mb-4">
                            Chronische Erkrankungen*
                          </Text>
                          <FormCheckbox
                            disabled={disabled}
                            className="big"
                            name="acceptHealthTerms"
                            label="Mir ist bewusst, dass untenstehende Vorerkrankungen bzw. Therapien ein erhöhtes, gesundheitliches Risiko im Zusammenhang mit einer COVID-19 Erkrankung verursachen können:"
                            checked={otherInformation.acceptHealthTerms}
                            error={getError(
                              'otherInformation',
                              'acceptHealthTerms'
                            )}
                          />
                          <div>
                            <ul className="list-disc grid grid-cols-2 ml-11">
                              <li>
                                <span>Diabetes mellitus</span>
                              </li>
                              <li>
                                <span>Bluthochdruck</span>
                              </li>
                              <li>
                                <span>Erkrankungen der Blutgefäße</span>
                              </li>
                              <li>
                                <span>Schlaganfall</span>
                              </li>
                              <li>
                                <span>
                                  Erkrankungen der Lunge (Asthma, COPD)
                                </span>
                              </li>
                              <li>
                                <span>
                                  Immunschwäche (angeboren / erworben)
                                </span>
                              </li>
                              <li>
                                <span>Cortisontherapie</span>
                              </li>
                              <li>
                                <span>Chemotherapie</span>
                              </li>
                              <li>
                                <span>Tumorerkrankungen</span>
                              </li>
                              <li>
                                <span>Immunsuppression</span>
                              </li>
                              <li>
                                <span>Antikörpertherapie</span>
                              </li>
                              <li>
                                <span>Strahlentherapie</span>
                              </li>
                            </ul>
                            <Accordion
                              items={{
                                boardHospital: {
                                  opener: () => (
                                    <div className="flex flex-row items-center text-md underline font-normal">
                                      Infos zum Bordhospital
                                    </div>
                                  ),
                                  content: (
                                    <div className="text-md">
                                      Das Bordhospital entspricht einer modernen
                                      Notfallambulanz mit einer begrenzten
                                      Kapazität und Ausstattung für Diagnostik
                                      und Therapie. Im Zweifelsfall konsultieren
                                      Sie bitte Ihren Hausarzt oder einen
                                      Reisemediziner, ob der Mitreise etwas im
                                      Wege steht. TUI Cruises kann hierzu keine
                                      Aussage treffen. Bitte bringen Sie für
                                      alle Fälle Unterlagen mit, die bei
                                      bedeutsamen Vorerkrankungen den aktuellen
                                      Stand belegen.
                                    </div>
                                  ),
                                },
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    )}

                    {showPregnant24Weeks && (
                      <div className="px-1 md:col-span-2 flex flex-col gap-2">
                        <Text type="h3" className="mb-4">
                          Schwangerschaft*
                        </Text>
                        <FormCheckbox
                          disabled={disabled}
                          className="big"
                          name="pregnant24Weeks"
                          label="Hiermit versichere ich, dass ich mich bei Reiseantritt nicht in der 24. Schwangerschaftswoche oder darüber hinaus befinde."
                          checked={otherInformation.pregnant24Weeks}
                          error={getError(
                            'otherInformation',
                            'pregnant24Weeks'
                          )}
                        />
                      </div>
                    )}
                  </div>
                  <div className="my-4">* Pflichtfeld</div>
                </FormBlock>
              ),
            },
          }}
        />
        <div className="l-row l-mod">
          <div className="l-col">&nbsp;</div>
          <div className="l-col double right l-right">
            {disabled ? (
              isCheckinSubRoute ? (
                <Button as={Link} variant="dark" size="lg" to="/checkin">
                  Zur Check-in Übersicht
                </Button>
              ) : (
                <Button as={Link} variant="dark" size="lg" to="/manifest">
                  Zur Manifest-Übersicht
                </Button>
              )
            ) : manifestData.isSending ? (
              <LoadingIndicator />
            ) : (
              <FormButton disabled={manifestData.isNotReady} big dark>
                Daten speichern
              </FormButton>
            )}
          </div>
        </div>
      </Form>
    );
  }
}

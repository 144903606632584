// @flow
import React, { Fragment, useState } from 'react';
import { PdfMeta } from '../../api/travelDocuments';
import AccordionSingleMode from '../AccordionSingleMode';
import Download from '../../../assets/svg/inline/download.inline.svg';
import styled from 'styled-components';
import type { TravelDocumentsStore, TravelTextType, AdditionalType } from '../../stores/TravelDocumentsStore';
import isInAppView from '../../utils/isInAppView';

type StaticTextType = {
  texte: TravelTextType,
};
const StaticTextEmpty = (props: StaticTextType) => {
  const { texte } = props;
  if (!texte.subline) {
    return null;
  }
  return (
    <div
      className="text empty"
      dangerouslySetInnerHTML={{
        __html: texte.subline,
      }}
    />
  );
};

const AccordionContent = styled.div`
  ul {
    list-style: disc;
    padding: 0 18px 10px;
  }
`;

const StaticText = (props: StaticTextType) => {
  const { texte } = props;
  return (
    <div className="text">
      <p
        dangerouslySetInnerHTML={{
          __html: texte.subline,
        }}
      />
      {texte.additional &&
        texte.additional.map((item: AdditionalType, index) => {
          return (
            <AccordionSingleMode
              key={index}
              items={[
                {
                  header: item.headline,
                  content: (
                    <AccordionContent
                      dangerouslySetInnerHTML={{
                        __html: item.text,
                      }}
                    />
                  ),
                },
              ]}
            />
          );
        })}
    </div>
  );
};

const Spinner = () => <div className="spinner-small" />;

type PropsType = {
  documents?: PdfMeta[] | null,
  travelDocumentsStore: TravelDocumentsStore,
  texte: TravelTextType,
};

const TravelDocumentsList = (props: PropsType) => {
  const { documents, travelDocumentsStore, texte } = props;
  const [loadingIndex, setLoadingIndex] = useState(-1);
  const [loadingAll, setLoadingAll] = useState(false);
  const loading = loadingIndex >= 0 || loadingAll;

  if (!documents || (documents && documents.length <= 0)) {
    return <StaticTextEmpty texte={texte || {}} />;
  }

  return (
    <Fragment>
      <StaticText texte={texte || {}} />
      {documents &&
        documents.map((pdfMeta, i) => (
          <div
            key={i}
            className={`pdf-list-item${loading ? ' loading' : ''}${i === loadingIndex ? ' current' : ''}`}
            onClick={() => {
              if (loading) return;
              setLoadingIndex(i);
              travelDocumentsStore.fetchPdf(pdfMeta, !!isInAppView).then((x) => {
                if (x) {
                  // x is blob
                  const url = window.URL.createObjectURL(x);
                  window.open(url, '_self');
                }

                setLoadingIndex(-1);
              });
            }}
          >
            <span className="name">{pdfMeta.name}</span>
            {i === loadingIndex ? <Spinner /> : <Download />}
          </div>
        ))}

      <div className="button-wrapper">
        <div
          className={`download-all${loading ? ' loading' : ''}`}
          onClick={() => {
            if (loading) return;
            setLoadingAll(true);
            travelDocumentsStore.fetchZip('Reiseunterlagen.zip', !!isInAppView).then((x) => {
              if (x) {
                // x is blob
                const url = window.URL.createObjectURL(x);
                window.open(url, '_self');
              }

              setLoadingAll(false);
            });
          }}
        >
          {loadingAll ? <Spinner /> : <Download />} Alles herunterladen
        </div>
      </div>
    </Fragment>
  );
};

export default TravelDocumentsList;
